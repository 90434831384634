<template>
    <div :class="['eden-plan--summary py-4 px-3', serciceClass]">
        <div>
            <span>Service type</span>
            <p>{{ data.serviceType }}</p>
        </div>
        <div>
            <span>Frequency</span>
            <p>{{ formatText(frequencyLabel(data.frequency)) }}</p>
        </div>
        <div v-if="data.serviceDays">
            <span>Service Day(s)</span>
            <p>{{ formatToTitleCase(data.serviceDays.join(", ")) }}</p>
        </div>
        <div>
            <span>Quantity</span>
            <p>{{ formatServiceUnit(service, data.quantity, true) }}</p>
        </div>


        <!-- <div>
            <span> Number of employees</span>
            <p>{{ data.employeesCount }}</p>
        </div> -->

        <!-- <div class="is-flex is-justify-end mt-2">
        <div class="is-flex pointer">
          <img
            :src="getImage('edit-pencil.svg')"
            class="text-green-primary ml-2"
            alt="Edit Subscription name"
          />
          <div @click="editService" class="text-primary ml-2">Edit this {{ service }} plan</div>
        </div>
      </div> -->
    </div>
</template>
  
<script >

export default {
    props: {
        data: Object,
        service: String,
        subscriptionId: Number
    },
    data() {
        return {}
    },

    computed: {
        serciceClass() {
            return this.service === "meal" ? "food" : this.service
        }
    },
    methods: {

    }
}
</script>
  
<style lang="scss" scoped>
.eden-plan--summary {
    border-radius: 8px;
    padding: 20px;

    &.beauty {
        background: var(--eden-pink-nonary);
        border: 1px solid var(--eden-pink-quaternary);
    }

    &.cleaning {
        background: var(--eden-orange-nonary);
        border: 1px solid var(--eden-orange-quaternary);
    }

    &.laundry {
        background: var(--eden-purple-nonary);
        border: 1px solid var(--eden-purple-quaternary);
    }

    &.food {
        background: var(--eden-green-nonary);
        border: 1px solid var(--eden-green-quaternary);
    }

    &.info {
        background: transparent;
        border: 1px solid var(--eden-grey-quinary);
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    span {
        color: var(--eden-grey-secondary);
        width: 40%;
        font-size: 1rem;
    }

    p {
        color: var(--eden-grey-secondary);
        font-size: 1rem;
    }
}
</style>
  