<template>
    <div class="">
        <section v-if="!loading">
            <eden-page-header title="Customer" :subtitle="$route.query.company_name" :section="'Subscriptions Group(s)'" />
            <el-tabs v-model="tab" >
                <el-tab-pane label="Active Subscriptions" name="active">
                    <el-row :gutter="20" v-for="sub in activeSubscriptions" :key="sub.name">
                        <el-col :span="9" class="h-100">
                            <subscription-overview class="h-100" :subscription="sub" />
                        </el-col>
                        <el-col :span="15">
                            <subscription-service-breakdown class="h-100" :subscription="sub" />
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="Draft Subscriptions" name="inactive">
                    <el-row :gutter="20" v-for="sub in inactiveSubscriptions" :key="sub.name">
                        <el-col :span="9" class="h-100">
                            <subscription-overview class="h-100" :subscription="sub" />
                        </el-col>
                        <el-col :span="15">
                            <subscription-service-breakdown class="h-100" :subscription="sub" @fetch-subscriptions ="fetchSubscription" />
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>

        </section>

        <p v-else>Loading...</p>

    </div>
</template>

<script>
import subscriptionRequest from "../../requests/subscriptions/subscription";
import SubscriptionOverview from "@/components/Business/Subscription/SubscriptionOverview.vue";
import SubscriptionServiceBreakdown from "@/components/Business/Subscription/SubscriptionServiceBreakdown.vue";
export default {
    components: {
        SubscriptionOverview,
        SubscriptionServiceBreakdown
    },

    data() {
        return {
            tab: 'active',
            loading: false
        }
    },

    created() {
        this.fetchSubscription()
    },

    // mounted(){
    //     if(this.activeSubscriptions.length ===0){
    //         this.tab = "inactive"
    //     }
    // },

    watch:{
        activeSubscriptions(newval){
            if(!newval.length){
                this.tab = "inactive"
            }
        }  
    },

    computed: {
        customerId() {
            return this.$route.params.id;
        },


        activeSubscriptions() {
            return this.$store.getters.get_active_subscription
        },

        inactiveSubscriptions() {
            return this.$store.getters.get_inactive_subscription
        },
    },

    methods: {
        fetchSubscription() {
            this.loading = true;
            subscriptionRequest
                .getCompanySubscriptions(this.customerId)
                .then((response) => {
                    this.$store.commit('SAVE_SUBSCRIPTION', response.data.data)
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    const errorMessage = error.response.data;
                    if (errorMessage.errors) {
                        const errorKeys = Object.keys(errorMessage.errors);
                        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                    } else {
                        this.$message.error(errorMessage.message);
                    }
                });
        },
    }
}
</script>