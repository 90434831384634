<template>
    <eden-container class="no-scroll" border :max-height="false">
      <p class="text-grey-primary text-bold mb-1 font-xl mb-3">
        {{ subscription.name }}
      </p>
      <div class="overview-card bordered is-flex mb-4">
        <div class="px-4 py-4">
          <div class="text-grey-quaternary mb-1">Next billing amount</div>
          <div>{{subscription.is_trial === 1 ? '--' :formatPrice(subscription.nextBillingAmount) }}</div>
        </div>
        <div class="px-4 py-4 border-left">
          <div class="text-grey-quaternary mb-1">Next billing date</div>
          <div>{{ subscription.is_trial === 1 ? '--' :formatDate(subscription.next_charge_date ,'m do, y')}}</div>
        </div>
      </div>
    </eden-container>
  </template>
  
  <script >
  export default{
    props:{
        subscription: {
            type:Object
        }
    },
    data(){
        return{}
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .overview-card {
    border-radius: 8px;
    border: 1px solid transparent;
  
    &.bordered {
      border-color: var(--eden-grey-septenary);
    }
  
    .border-left {
      border-left: 1px solid var(--eden-grey-septenary);
    }
  }
  .no-scroll{
    overflow-y: hidden;
  }
  </style>
  