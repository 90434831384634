var render = function render(){var _vm=this,_c=_vm._self._c;return _c('eden-container',{staticClass:"no-scroll",attrs:{"border":"","max-height":false}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":_vm.activeBreakdown ? 8 : 24}},[_c('div',{staticClass:"is-flex"},[_c('p',{staticClass:"text-grey-primary text-bold mb-1 font-xl mb-3"},[_vm._v(" Your services ")]),(!_vm.activeBreakdown.length && _vm.subscription.status=== 'active' )?_c('el-button',{staticClass:"align-self-start ml-auto",on:{"click":_vm.viewCycle}},[_vm._v("View subscription cycle")]):_vm._e(),(!_vm.activeBreakdown.length && _vm.subscription.status=== 'inactive')?_c('el-button',{staticClass:"align-self-start ml-auto",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.activate()}}},[_vm._v("Activate")]):_vm._e()],1),(!_vm.activeBreakdown)?_c('el-row',{attrs:{"type":"flex"}},_vm._l((Object.keys(_vm.subscription.plan)),function(service,ix){return _c('el-col',{key:ix,staticClass:"is-flex is-flex-column is-align-center",attrs:{"span":6}},[_c('div',{class:['service-item', service]},[_c('img',{staticClass:"service-item__thumbnail",attrs:{"src":_vm.serviceOptions.find(
                            (it) => it.name?.toLowerCase() === service)?.thumbnail,"alt":_vm.serviceOptions.find(
                                (it) => it.name?.toLowerCase() === service,
                            )?.name}})]),_c('div',{staticClass:"text-bold text-grey-primary pt-2 pb-1"},[_vm._v(" "+_vm._s(_vm.formatText(service))+" ")]),_c('div',{staticClass:"text-primary pointer",on:{"click":function($event){_vm.activeBreakdown = service}}},[_vm._v(" View breakdown ")])])}),1):_c('div',{staticClass:"is-flex is-flex-column is-align-center pointer"},[_c('div',{class:['service-item', _vm.activeBreakdown]},[_c('img',{staticClass:"service-item__thumbnail",attrs:{"src":_vm.serviceOptions.find(
                        (it) => it.name?.toLowerCase() === _vm.activeBreakdown,
                        )?.thumbnail,"alt":_vm.serviceOptions.find(
                            (it) => it.name?.toLowerCase() === _vm.activeBreakdown,
                        )?.name}})]),_c('div',{staticClass:"text-bold text-grey-primary pt-2 pb-1"},[_vm._v(" "+_vm._s(_vm.formatText(_vm.activeBreakdown))+" ")]),_c('div',{staticClass:"text-primary pointer",on:{"click":function($event){_vm.activeBreakdown = ''}}},[_vm._v(" Hide breakdown ")])])],1),_c('el-col',{attrs:{"span":_vm.activeBreakdown ? 16 : 0}},[_c('service-summary',{attrs:{"data":_vm.data,"service":_vm.activeBreakdown,"subscription-id":_vm.subscription.id}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }