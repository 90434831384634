<template>
    <eden-container class="no-scroll" border :max-height="false">
        <el-row type="flex">
            <el-col :span="activeBreakdown ? 8 : 24">
                <div class="is-flex ">
                    <p class="text-grey-primary text-bold mb-1 font-xl mb-3">
                        Your services
                    </p>

                    <el-button class="align-self-start ml-auto" v-if="!activeBreakdown.length && subscription.status=== 'active' " @click="viewCycle">View
                        subscription
                        cycle</el-button>
                    <el-button class="align-self-start  ml-auto" v-if="!activeBreakdown.length && subscription.status=== 'inactive'" :loading="loading"
                        @click="activate()">Activate</el-button>
                        
                </div>

                <el-row type="flex" v-if="!activeBreakdown">
                    <el-col :span="6" class="is-flex is-flex-column is-align-center"
                        v-for="(service, ix) in Object.keys(subscription.plan)" :key="ix">
                        <div :class="['service-item', service]">
                            <img class="service-item__thumbnail" :src="serviceOptions.find(
                                (it) => it.name?.toLowerCase() === service)?.thumbnail
                                " :alt="serviceOptions.find(
                                    (it) => it.name?.toLowerCase() === service,
                                )?.name "
                            />
                        </div>
                        <div class="text-bold text-grey-primary pt-2 pb-1">
                            {{ formatText(service) }}
                        </div>
                        <div class="text-primary pointer" @click="activeBreakdown = service">
                            View breakdown
                        </div>
                    </el-col>
                </el-row>

                <div v-else class="is-flex is-flex-column is-align-center pointer">
                    <div :class="['service-item', activeBreakdown]">
                        <img class="service-item__thumbnail" :src="serviceOptions.find(
                            (it) => it.name?.toLowerCase() === activeBreakdown,
                            )?.thumbnail
                                " :alt="serviceOptions.find(
                                (it) => it.name?.toLowerCase() === activeBreakdown,
                            )?.name" 
                        />
                    </div>
                    <div class="text-bold text-grey-primary pt-2 pb-1">
                        {{ formatText(activeBreakdown) }}
                    </div>
                    <div class="text-primary pointer" @click="activeBreakdown = ''">
                        Hide breakdown
                    </div>
                </div>
            </el-col>
            <el-col :span="activeBreakdown ? 16 : 0">
                <service-summary :data="data" :service="activeBreakdown" :subscription-id="subscription.id" />
            </el-col>
        </el-row>
    </eden-container>
</template>
  
<script>
import { mapState } from "vuex";
import subscriptionRequest from "../../../requests/business/subscription";
import EdenContainer from "@/components/Global/EdenContainer.vue";
//   import useSupport from "@/composables/support";
import ServiceSummary from "./ServiceSummary.vue";

export default {
    props: {
        subscription: {
            type: Object
        }
    },
    components: {
        EdenContainer,
        ServiceSummary
    },
    computed: {
        ...mapState({
            serviceOptions: (state) => state.services.serviceOptions
        })
    },

    data() {
        return {
            data: {
                service: '',
                serviceType: "",
                serviceDays: [],
                frequency: "",
                quantity: 0,
                employeesCpunt: 0,
            },
            loading:false,

            activeBreakdown: ''
        }
    },
    watch: {
        activeBreakdown(change) {
            if (change) {
                let serviceBreakdown = this.subscription.plan[this.activeBreakdown === 'food' ? 'meal' : this.activeBreakdown]
                this.data['service'] = this.activeBreakdown === 'meal' ? 'food' : this.activeBreakdown
                this.data['serviceType'] = serviceBreakdown.serviceType ? this.formatText(serviceBreakdown.serviceType) : this.activeBreakdown 
                this.data['serviceDays'] = serviceBreakdown.service_day
                this.data['frequency'] = serviceBreakdown.frequency
                this.data["quantity"] = serviceBreakdown.qty
                // this.data["employeesCount"] = this.subscription.employee_count
            }

           
        },

    },
    methods: {
        viewCycle() {
            this.$router.push({
                name: 'business.corporate-subscription-cycle',
                query: { company_name: this.$route.query?.company_name, subscription: this.subscription.name, id: this.subscription.id }
            })
        },

        async activate() {
            this.loading = true
            subscriptionRequest.activate(this.subscription.id)
            .then((response) => {
                this.$message.success(response.data.message);
                this.loading = false;
                this.$emit('fetch-subscriptions')
            }).catch((error)=>{
                this.loading = false;
                const errorMessage = error.response.data;
                if (errorMessage.errors) {
                    const errorKeys = Object.keys(errorMessage.errors);
                    this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                } else {
                    this.$message.error(errorMessage.message);
                }
            })
        }
    }
}
</script>
  
<style lang="scss" scoped>
.service-item {
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &__thumbnail {
        width: 30px;
        height: 30px;
    }

    &.beauty {
        background: var(--eden-pink-septenary);
        border: 1px solid var(--eden-pink-tertiary);
    }

    &.cleaning {
        background: var(--eden-orange-septenary);
        border: 1px solid var(--eden-orange-tertiary);
    }

    &.laundry {
        background: var(--eden-purple-septenary);
        border: 1px solid var(--eden-purple-tertiary);
    }

    &.meal {
        background: var(--eden-green-septenary);
        border: 1px solid var(--eden-green-tertiary);
    }

    &.info {
        background: transparent;
        border: 1px solid var(--eden-grey-quinary);
    }
}

.no-scroll {
    overflow-y: hidden !important;
}
</style>
  